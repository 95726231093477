import React, { FC, useState } from 'react'
import { Spinner } from '../Shared/Spinner'
import { NoDataError, UnexpectedError } from '../Shared/Error'
import { Accordion, Card, Tabs, Tab, DefaultPage, Button, FormikInput } from 'navex-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useGetPowerBiReportsInfo } from './ManagePowerBIApi'
import { PowerBICreateReport } from './PowerBICreateReport'
import { PowerBIReport } from './PowerBIReport'
import { models } from 'powerbi-client'
import ManagePowerBIRowDraft from './ManagePowerBIRowDraft'
import ManagePowerBIRowEnable from './ManagePowerBIRowEnable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';

interface IProps {
  permissions: string[]
}
export const ManagePowerBIRoute: FC<IProps> = (props) => {

  const [reportMode, setReportMode] = useState<"Create" | "View" | "Edit" | "">("")

  const [reportId, setReportId] = useState("")
  const [embedUrl, setEmbedUrl] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [newReportName, setNewReportName] = useState("")

  const [{ reportInfo, loading, status, errors }, reFetch] = useGetPowerBiReportsInfo()
  const [activeCardIndex, setActiveCardIndex] = useState(-1)

  const changeActiveCardIndex = (newActiveCardIndex: number) => {
    setActiveCardIndex(newActiveCardIndex)
  }

  const handleCreateDashbordModalXClose = () => {
    setShowModal(false)
  }

  const closeRolesModalXButton = (
    <button
      id="closeXButton"
      tabIndex={0}
      className="close modal-header-close"
      onClick={handleCreateDashbordModalXClose}
      aria-label={"close"}
      type="button"
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  )

  const checkReportExist = (args: string | undefined) => {
    if (!args)
      return false
    const repnames1 = reportInfo.enabledReports.map(r => r.name)
    if (repnames1.includes(args))
      return false

    const repnames2 = reportInfo.reports.map(r => r.name)
    if (repnames2.includes(args))
      return false
    return true
  }

  if (errors.length) return <UnexpectedError />
  if (loading || !status) return <Spinner />

  if(embedUrl === "" && reportId === "" && newReportName !== "" && reportMode === "Edit")
  {
    const embed = reportInfo.reports.find(s => s.name === newReportName)
    if(!!embed)
    {
      setEmbedUrl(embed.embedUrl)
      setReportId(embed.id)
      setNewReportName("")
    }
  }

  switch (reportMode) {
    case 'Create':
      return <PowerBICreateReport datasets={reportInfo.datasets} newReportName={newReportName} setReportMode={setReportMode} reFetch={reFetch}/>
    case 'View':
      return <PowerBIReport reportId={reportId} embedUrl={embedUrl} reportMode={models.ViewMode.View} />
    case 'Edit':
      return <PowerBIReport reportId={reportId} embedUrl={embedUrl} reportMode={models.ViewMode.Edit} />

    default:
      return (<DefaultPage>
        <Modal isOpen={showModal}>
          <Formik
            initialValues={{
              reportName: ''
            }}
            onSubmit={(values, actions) => {
              setNewReportName(values.reportName)
              actions.setSubmitting(false)
              setShowModal(false)
              setReportMode("Create")
            }}

            validationSchema={Yup.object().shape({
              reportName: Yup.string()
                .min(2, 'Must be at least 2 characters')
                .max(30, 'Must be 30 characters or less')
                .matches(/^[a-zA-Z0-9 ]*$/, 'Special characters not allowed')
                .required('Enter Dashboard name')
                .test("isExist", "A Report with this name already exists", checkReportExist)
            })}
          >
            {({ values, errors }) => (
              <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <ModalHeader close={closeRolesModalXButton}>
                  Add New Report
                </ModalHeader>
                <ModalBody>
                  <div id="create-report" className="form-group">
                    <div className="form-control-feedback-group">
                      <Field
                        id="page-name"
                        name="reportName"
                        required
                        label="Report Name"
                        component={FormikInput}
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  {
                    <Button purpose="primary" type="submit" id="btnCreate" disabled={values.reportName.length === 0 || !!errors.reportName}
                      style={{ float: "right" }}>Create</Button>
                  }
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
        <Tabs id={`viewReportTabBar`} withPanelBorder={false} activeTabIndex={0}>
          <Tab id={"EthicsPointProduct"} title={"Incident Management"}>
            <Accordion id="Accordion" activeCardIndex={activeCardIndex} onActiveCardChange={changeActiveCardIndex}>
              <Card actions={<Button purpose="primary" id="createReport" style={{ float: "right" }} onClick={() => setShowModal(true)}>New Report</Button>} title="Draft Reports" open={true} collapsible={true} id="EthicsPoint_DraftReports">
                <table className="table table-striped table-bordered" id="DraftReports">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }} >Report Name</th>
                      <th style={{ width: "15%" }}>
                      </th>
                      <th style={{ width: "15%" }}>
                      </th>
                      <th style={{ width: "15%" }}>
                      </th>
                      <th style={{ width: "20%", paddingLeft: "22px" }} > Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      reportInfo.reports.length > 0
                        ?
                        reportInfo.reports.map(x =>
                          <ManagePowerBIRowDraft key={x.id} id={x.id} name={x.name} embedUrl={x.embedUrl} setReportMode={setReportMode}
                            setReportId={setReportId} setEmbedUrl={setEmbedUrl} isDraft={true} workSpaceId={reportInfo.workspaceId} status={x.status}
                            reFetch={reFetch} favorite={false} description={x.description} collection={x.collection} />)
                        :
                        <tr>
                          <td colSpan={3}>
                            <NoDataError />
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
              </Card>
              <Card title="Enabled Reports" open={true} collapsible={true} id="EthicsPoint_EnabledReports">
                <table className="table table-striped table-bordered" id="publishedReports">
                  <thead>
                    <tr id="HeadersForPublishedReportTable">
                      <th style={{ width: "25%" }}>Report Name</th>
                      <th style={{ width: "15%" }}>Description</th>
                      <th style={{ width: "15%" }}>Collection</th>
                      <th style={{ width: "15%" }}>Status</th>
                      <th style={{ width: "15%", paddingLeft: "22px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      reportInfo.enabledReports.length > 0
                        ?
                        reportInfo.enabledReports.map(x =>
                          <ManagePowerBIRowEnable key={x.id} id={x.id} name={x.name} embedUrl={x.embedUrl} setReportMode={setReportMode}
                            setReportId={setReportId} setEmbedUrl={setEmbedUrl} isDraft={false} workSpaceId={reportInfo.workspaceId} status={x.status}
                            reFetch={reFetch} favorite={x.favorite} description={x.description} collection={x.collection} permissions={props.permissions} />)
                        :
                        <tr>
                          <td colSpan={3}>
                            <NoDataError />
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
              </Card>
            </Accordion>
          </Tab>
        </Tabs>
      </DefaultPage>)
  }
}