import React, { FC } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'navex-react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { object, string } from 'yup'
import { Toasts, ToastId } from 'navex-react'
import { ButtonRow, FormikOption, FormikSelect } from 'navex-react'
import { IGetCustomFormula, useUpsertCustomFormula } from './CustomFormulasApi'
import useButtonLoader from './CustomFormulaLoader'


interface IProps {
  setIsCustomFormulaEdit: (value: boolean) => void,
  reFetchData: () => void,
  customFormula?: IGetCustomFormula,
  customFormulaInfo: IGetCustomFormula[],
}

const format = [
  { name: "General" },
  { name: "Currency" },
  { name: "Decimal Number" },
  { name: "Whole Number" },
  { name: "Percentage" },
  { name: "Scientific" },
  { name: "Custom" }
]

export const CustomFormulasDetailsView: FC<IProps> = (props) => {

const [isAdding, setIsAdding] = useButtonLoader("Add", "Adding...");
const [isUpdating, setIsUpdating] = useButtonLoader("Update", "Updating...");

  let toastId: ToastId = 0
  const [upsert] = useUpsertCustomFormula({
    onSuccess: (_, data) => {
      if (data?.id)
        toastId = Toasts.success("Custom Formula Edited sucessfully")
      else
        toastId = Toasts.success("Custom Formula Added sucessfully")

      props.reFetchData()
      handleFormulaClose()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const orignalUsername = props.customFormula?.name

  const isCustomFormulaNameExist = (proposedFieldName: string | undefined) => {
    if (!proposedFieldName)
      return false
    let existingFieldNames: string[] = props.customFormulaInfo.map(s => s.name.toUpperCase())
    return !existingFieldNames.includes(proposedFieldName.toUpperCase())
  }

  const ValidateEditName = (editName: string) => {
    if (editName === orignalUsername) {
      return true
    }
    return (isCustomFormulaNameExist(editName))
  }

  const handleFormulaClose = () => {
    props.setIsCustomFormulaEdit(false)
  }

  return (<>
    <Formik
      initialValues={{
        FormulaName: props.customFormula === undefined ? "" : props.customFormula.name,
        customFormula: props.customFormula === undefined ? "" : props.customFormula.formula,
        Format:props.customFormula === undefined ? "" : props.customFormula.format,
      }}

      onSubmit={async (values, actions) => {
        setIsAdding(true)
        setIsUpdating(true) 
        await upsert({
          name: values.FormulaName,
          formula: values.customFormula,
          id: props.customFormula === undefined ? undefined : props.customFormula.id,
          datasetId: undefined,
          format:values.Format,
        })
    
        actions.setSubmitting(false)
        setIsAdding(false) 
        setIsUpdating(false)
        actions.resetForm()
        
      }}

      validationSchema={object().shape({
        customFormula: string()
          .required("Enter Custom Formula")
          .max(4000, "Must be 4000 characters or less"),
        FormulaName: string()
          .required("Enter Custom Formula Name")
          .test("isUnique", "Name already exists", ValidateEditName),
          Format: string()
            .required("Select Format By value")
      })}
      validateOnBlur
    >
      {({ values,
        errors,
        touched,
        setFieldValue,
        isSubmitting, }) => (
        <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="row">
            <div className="col-12 col-sm-6">

            <div className="form-group">
                  <label className="control-label" htmlFor="Format">Select Format
                    <span className="validation-required">{" * "}</span>
                    {errors.Format && touched.Format ? (<span className="validation-label" aria-hidden="false">{errors.Format}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="Format" component={FormikSelect} name="Format"
                      aria-invalid={errors.Format && touched.Format} aria-required="true"
                      required
                      onChange={(value: any) => { setFieldValue('Format', value) }} >
                      {
                        format.map(d => {
                          return <FormikOption value={d.name} key={d.name}>{d.name}</FormikOption>
                        })
                      }</Field>
                  </div>
                </div>

              <div className="form-group">           
                <label className="control-label" htmlFor="customFormula">Custom Formula
                  <span className="validation-required">{" * "}</span>
                  {errors.customFormula && touched.customFormula ? (<span className="validation-label" aria-hidden="false">{errors.customFormula}</span>) : null}
                </label>
                <div className="form-control-feedback-group">
                  <Field id="customFormula" component="textarea" name="customFormula" placeholder="SUM('Case Counts'[Nbr Cases])" className="form-control no-ie-clear" maxLength={5000} rows="8" aria-describedby="name-help-block"
                    aria-invalid={errors.customFormula && touched.customFormula} aria-required="true" required />
                  <span className="add-clear-x form-control-feedback" style={{
                    color: "rgb(51, 51, 51)",
                    cursor: "pointer",
                    textDecoration: "none",
                    overflow: "hidden",
                    position: "absolute",
                    pointerEvents: "auto",
                    right: "3%",
                    top: "-3%",
                    zIndex: 100,
                    display: values.customFormula.length > 0 ? undefined : "none",
                    height: "10%"
                  }}
                    onClick={() => setFieldValue("customFormula", "")}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label" htmlFor="FormulaName">Name
                  <span className="validation-required">{" * "}</span>
                  {errors.FormulaName && touched.FormulaName ? (<span className="validation-label" aria-hidden="false">{errors.FormulaName}</span>) : null}
                </label>
                <div className="form-control-feedback-group">
                  <Field id="FormulaName" name="FormulaName" className="form-control no-ie-clear" aria-describedby="name-help-block"
                    aria-invalid={errors.FormulaName && touched.FormulaName} aria-required="true" required />
                  <span className="add-clear-x form-control-feedback" style={{
                    color: "rgb(51, 51, 51)",
                    cursor: "pointer",
                    textDecoration: "none",
                    overflow: "hidden",
                    position: "absolute",
                    pointerEvents: "auto",
                    right: 0,
                    top: 0,
                    zIndex: 100,
                    display: values.FormulaName.length > 0 ? undefined : "none",
                  }}
                    onClick={() => setFieldValue("FormulaName", "")}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </div>


              <div className="form-group d-flex justify-content-end">
                <ButtonRow>
                  <Button id="btnCancelFormula" disabled= {isSubmitting} onClick={handleFormulaClose}>Cancel</Button>
                  {props.customFormula === undefined
                    ? <Button id="btnAddFormula"  purpose="primary" type="submit" disabled={values.customFormula.length === 0 || !!errors.customFormula || isSubmitting} innerRef= {isAdding}>Add</Button>
                    : <Button id="btnUpdateFormula" purpose="primary" type="submit" disabled={values.customFormula.length === 0 || !!errors.customFormula || isSubmitting} innerRef= {isUpdating}>Update</Button>
                  }
                </ButtonRow>
              </div>
            </div>
          </div>
        </Form>
      )
      }
    </Formik >
  </>
  )
}
