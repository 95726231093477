import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FC, useState } from 'react'
import { Button, DefaultPage, ToastId, Toasts, FormikOption, FormikSelect, FormikDatePicker, Checkbox, ButtonRow } from 'navex-react'
import { ModalBody, ModalFooter } from "reactstrap"
import { Field, Form, Formik } from "formik"
import { object, string } from "yup"
import { Timezones } from '../PowerBIEmbeddedDashboard/TimeZones'
import { setHours, setMinutes } from "date-fns"
import { ButtonSpinner } from '../Shared/Spinner'
import { IGetSubscription, usePowerBiSubscription, useUpdateSubscription } from "./SubscriptionApi"
import { NoDataError } from "../Shared/Error"
import { pathPrefix } from "../../Api/urls"
import { insightAppHistory } from "../InsightsApp/InsightsApp"
import { SubscriptionListItem } from "./SubscriptionListItem"


interface IProps {
    SubscriptionDetails: IGetSubscription[],
    leftNavMenu: string[],
    refetch: () => void,
    username:string
}

const timeZones = Timezones
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
export const ManageSubscription: FC<IProps> = (props) => {
    const subscribeNames: string[] = props.SubscriptionDetails.map((item: any) => item.subscriptionName);
    const [showModal, setShowModal] = useState(false)
    const [path, setPath] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [chooseWeekday, setWeekday] = useState([days[0]])
    const [subscriptionId, setSubscriptionId] = useState(undefined as number | undefined)
    const subscriptionDetails = props.SubscriptionDetails.filter((item: any) => item.subscriptionId === subscriptionId)
    switch (subscriptionDetails[0]?.frequency) {
        case 2:
            subscriptionDetails[0].frequency = "Daily"
            break
        case 3:
            subscriptionDetails[0].frequency = "Weekly"
            break
        case 4:
            subscriptionDetails[0].frequency = "Monthly"
            break
    }
    let toastId: ToastId = 0
    const [upsert] = usePowerBiSubscription({
        onSuccess: () => {
            toastId = Toasts.success("Saved Successfully")
            props.refetch()
            handleClose()
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target
        if (checked) {
            setWeekday(
                [...chooseWeekday, value]
            )
        }
        else {
            setWeekday(
                chooseWeekday.filter(
                    (e: string) => e !== value
                )
            )
        }
    }

    const [update] = useUpdateSubscription({
        onSuccess: () => {
            toastId = Toasts.success("Saved Successfully")
            props.refetch()
            handleClose()
            setSubscriptionId(undefined)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const handleClose = () => {
        if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== "*") {
            insightAppHistory.push(`${pathPrefix}/Manage_Subscription/*`, {})
        }
        else {
            setShowModal(false)
            setPath(false)
            setSubscriptionId(undefined)
        }
    }

    const computeWeekdayString = (weekdays: string[]) => {
        let result = ""
        weekdays.forEach((day, index) => {
            if (index === weekdays.length - 1) {
                result += "\"" + day + "\""
            }
            else {
                result += "\"" + day + "\"" + ","
            }
        })
        return result
    }
    let reportNames: string[] = [];
    props.leftNavMenu.map((item: any) => item.map((report: any) => reportNames.push(report.displayName)));

    return (<DefaultPage>
        {showModal || (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== "*" && path) ? <Formik
            initialValues={subscriptionId !== undefined ? {
                SubscriptionName: subscriptionDetails[0].subscriptionName,
                ReportName: subscriptionDetails[0].reportName,
                EmailRecipient: subscriptionDetails[0].recipients,
                Format: subscriptionDetails[0].reportTypeFormat,
                StartDate: new Date(new Date(subscriptionDetails[0].subscriptionStartDate).setHours(0, 0, 0)),
                EndDate: "",
                Frequency: subscriptionDetails[0].frequency,
                WeekDay: subscriptionDetails[0].weekOfFrequency ? subscriptionDetails[0].weekOfFrequency.split(",").map((item: string) => item.replace(/"/g, "")) : "",
                MonthOnDays: subscriptionDetails[0].monthOfFrequency ? subscriptionDetails[0].monthOfFrequency : "",
                Hour: new Date(subscriptionDetails[0].subscriptionStartDate).getHours() > 12 ? (new Date(subscriptionDetails[0].subscriptionStartDate).getHours() - 12).toString() : new Date(subscriptionDetails[0].subscriptionStartDate).getHours().toString(),
                Mins: new Date(subscriptionDetails[0].subscriptionStartDate).getMinutes().toString() === "0" ? "00" : new Date(subscriptionDetails[0].subscriptionStartDate).getMinutes().toString(),
                Meridiem: new Date(subscriptionDetails[0].subscriptionStartDate).getHours() >= 12 ? "PM" : "AM",
                TimeZone: subscriptionDetails[0].timezone
            } : {
                SubscriptionName: "",
                ReportName: window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== "*" ? props.leftNavMenu.map((item: any) => item.map((report: any) => { if (report.reportId === window.location.href.substring(window.location.href.lastIndexOf("/") + 1)) { return report.displayName } })).toString().replace(/,/g, "").trim() : "",
                EmailRecipient: "",
                Format: "",
                StartDate: "",
                EndDate: "",
                Frequency: "",
                WeekDay: "",
                MonthOnDays: "",
                Hour: "",
                Mins: "",
                Meridiem: "",
                TimeZone: ""
            }}
            onSubmit={async (values, actions) => {
                setIsSaving(true)
                actions.setSubmitting(true)
                let reportId = ""
                props.leftNavMenu.map((item: any) => item.map((report: any) => { if (report.displayName === values.ReportName) { reportId += report.reportId } }));
                var hour = values.Meridiem === "PM" && values.Hour !== "12" ? Number(values.Hour) + 12 : values.Meridiem === "AM" && values.Hour === "12" ? Number(values.Hour) - 12 : Number(values.Hour)
                subscriptionId === undefined ? await upsert({
                    ReportId: reportId,
                    SubscribeName: values.SubscriptionName,
                    RecipientMailId: values.EmailRecipient,
                    ReportTypeFormat: values.Format,
                    SubscriptionStartDate: new Date(new Date(values.StartDate).setHours(new Date(values.StartDate).getHours() + 6 + hour, (new Date(values.StartDate).getMinutes() - 30) + Number(values.Mins), 0)),
                    Frequency: values.Frequency,
                    Status: true,
                    Timezone: values.TimeZone,
                    ReportUrl: window.location.href,
                    WeekOfFrequency: values.Frequency === "Weekly" && chooseWeekday ? computeWeekdayString(chooseWeekday) : null,
                    MonthOfFrequency: values.Frequency === "Monthly" && values.MonthOnDays ? values.MonthOnDays : null,
                    author:props.username
                }) :
                    await update({
                        SubscriptionId: subscriptionId,
                        ReportId: reportId,
                        SubscribeName: values.SubscriptionName,
                        RecipientMailId: values.EmailRecipient,
                        ReportTypeFormat: values.Format,
                        SubscriptionStartDate: new Date(new Date(values.StartDate).setHours(hour + 5, Number(values.Mins) + 30, 0)),
                        Frequency: values.Frequency,
                        Timezone: values.TimeZone,
                        ReportUrl: window.location.href,
                        WeekOfFrequency: values.Frequency === "Weekly" && chooseWeekday ? computeWeekdayString(chooseWeekday) : null,
                        MonthOfFrequency: values.Frequency === "Monthly" && values.MonthOnDays ? values.MonthOnDays : null,
                        author:subscriptionDetails[0].author
                    })
                setIsSaving(false)
                actions.setSubmitting(false)
                actions.resetForm()
            }}

            validationSchema={object().shape({
                SubscriptionName: subscriptionId === undefined ? string()
                    .required("Enter Subscription Name").notOneOf(subscribeNames, "Name already exists") : string().required("Enter Subscription Name"),
                ReportName: string()
                    .required("Choose Report Name"),
                EmailRecipient: string().matches(/^(?!.*(\b(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\b).*\b\1\b)(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/g, "Enter valid email recipient(s) separated by comma")
                    .required("Enter Email Recipient"),
                Format: string()
                    .required("Choose Format"),
                StartDate: string()
                    .required("Set Start Date"),
                Frequency: string()
                    .required("Choose Frequency"),
                Hour: string()
                    .required("Choose Hour(s)"),
                Mins: string()
                    .required("Choose Min(s)"),
                Meridiem: string()
                    .required("Choose AM/PM"),
                TimeZone: string()
                    .required("Choose Time Zone"),
                MonthOnDays: string().matches(/^(?!.*(\b(?:[1-9]|[1-2]\d|3[0-1])\b).*\b\1\b)(?:[1-9]|[1-2]\d|3[0-1])(?:,(?:[1-9]|[1-2]\d|3[0-1]))*$/g, "Enter valid non-repeating day(s) separated by comma")
            })}
            validateOnBlur
        >
            {({ values,
                errors,
                touched,
                setFieldValue,
                isSubmitting, }) => (
                <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label className="control-label" htmlFor="SubscriptionName">Subscription Name
                                        <span className="validation-required">{" * "}</span>
                                        {errors.SubscriptionName && touched.SubscriptionName ? (<span className="validation-label" aria-hidden="false">{errors.SubscriptionName}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="SubscriptionName" name="SubscriptionName" className="form-control" aria-describedby="name-help-block"
                                            aria-invalid={errors.SubscriptionName && touched.SubscriptionName} aria-required="true" placeholder="Enter name here"
                                            required />
                                        <span className="add-clear-x form-control-feedback" style={{
                                            color: "rgb(51, 51, 51)",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            overflow: "hidden",
                                            position: "absolute",
                                            pointerEvents: "auto",
                                            right: 0,
                                            top: 0,
                                            zIndex: "auto",
                                            display: values.SubscriptionName.length > 0 ? undefined : "none",
                                        }}
                                            onClick={() => setFieldValue("SubscriptionName", "")}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label" htmlFor="ReportName">Report Name
                                        <span className="validation-required">{" * "}</span>
                                        {errors.ReportName && touched.ReportName ? (<span className="validation-label" aria-hidden="false">{errors.ReportName}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="ReportName" component={FormikSelect} name="ReportName"
                                            aria-invalid={errors.ReportName && touched.ReportName} aria-required="true" title={values.ReportName ? values.ReportName : "Select Report Name"}
                                            required maxMenuHeight="200px" validationErrorMessage=""
                                            onChange={(value: any) => { setFieldValue('ReportName', value) }} >
                                            {reportNames.map(yo => {
                                                return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                            })}</Field>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label" htmlFor="EmailRecipient">Recipient(s)
                                        <span className="validation-required">{" * "}</span>
                                        {errors.EmailRecipient && touched.EmailRecipient ? (<span className="validation-label" aria-hidden="false">{errors.EmailRecipient}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="EmailRecipient" name="EmailRecipient" className="form-control" aria-describedby="name-help-block" placeholder="Enter email id('s) separated by comma"
                                            aria-invalid={errors.EmailRecipient && touched.EmailRecipient} aria-required="true"
                                            required />
                                        <span className="add-clear-x form-control-feedback" style={{
                                            color: "rgb(51, 51, 51)",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            overflow: "hidden",
                                            position: "absolute",
                                            pointerEvents: "auto",
                                            right: 0,
                                            top: 0,
                                            zIndex: "auto",
                                            display: values.EmailRecipient.length > 0 ? undefined : "none",
                                        }}
                                            onClick={() => setFieldValue("EmailRecipient", "")}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label" htmlFor="Format">Format
                                        <span className="validation-required">{" * "}</span>
                                        {errors.Format && touched.Format ? (<span className="validation-label" aria-hidden="false">{errors.Format}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="Format" component={FormikSelect} name="Format"
                                            aria-invalid={errors.Format && touched.Format} aria-required="true" title={values.Format ? values.Format : "Select Format"}
                                            required maxMenuHeight="200px" validationErrorMessage=""
                                            onChange={(value: any) => { setFieldValue('Format', value) }} >
                                            {['PDF', 'PPTX'].map(yo => {
                                                return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                            })}</Field>
                                    </div>
                                </div>

                                <label className="control-label" htmlFor="ScheduledDate">Scheduled Date
                                    <span className="validation-required">{" * "}</span>
                                </label>
                                <table id="ScheduledDate">
                                    <tbody>
                                        <tr>
                                            <th scope="col" style={{ columnWidth: "300px" }}><Field
                                                id="StartDate"
                                                name="StartDate"
                                                label="Start Date"
                                                required
                                                minDate={setHours(setMinutes(new Date(), 0), 12)}
                                                maxDate={values.EndDate ? values.EndDate : ""}
                                                shouldCloseOnSelect
                                                validationErrorMessage="Set Start Date"
                                                component={FormikDatePicker}
                                                openToDate={new Date()}
                                                onChange={(value: any) => {
                                                    setFieldValue('StartDate', value)
                                                }}
                                            />
                                            </th>
                                            <th scope="col" style={{ paddingLeft: "1rem" }} />
                                            <th scope="col" style={{ columnWidth: "300px" }}><Field
                                                id="EndDate"
                                                name="EndDate"
                                                label="End Date"
                                                minDate={values.StartDate ? values.StartDate : setHours(setMinutes(new Date(), 0), 12)}
                                                shouldCloseOnSelect
                                                component={FormikDatePicker}
                                                openToDate={new Date()}
                                                onChange={(value: any) => {
                                                    setFieldValue('EndDate', value)
                                                }}
                                            />
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="form-group">
                                        <label className="control-label" htmlFor="Frequency">Frequency
                                            <span className="validation-required">{" * "}</span>
                                            {errors.Frequency && touched.Frequency ? (<span className="validation-label" aria-hidden="false">{errors.Frequency.toString()}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="Frequency" component={FormikSelect} name="Frequency"
                                                aria-invalid={errors.Frequency && touched.Frequency} aria-required="true" title={values.Frequency ? values.Frequency : "Select Frequency"}
                                                required maxMenuHeight="200px" validationErrorMessage=""
                                                onChange={(value: any) => { setFieldValue('Frequency', value) }} >
                                                {['Daily', 'Weekly', 'Monthly'].map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })}</Field>
                                        </div>
                                    </div>
                                {values.Frequency === 'Weekly' ?
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="WeekDay">Weekday(s)
                                            <span className="validation-required">{" * "}</span>
                                            {chooseWeekday.length === 0 ? (<span className="validation-label" aria-hidden="false">{errors.WeekDay}</span>) : null}
                                        </label>
                                        <table id="Weekdays">
                                            <tr>
                                                <th scope="col">
                                                    {days.map(item =>
                                                        <Checkbox
                                                            defaultChecked={item === days[0] ? true : false}
                                                            className={item}
                                                            name={item}
                                                            label={item}
                                                            value={item}
                                                            id={item}
                                                            onChange={handleChange} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                                                    )}
                                                </th>
                                            </tr>
                                        </table>
                                    </div> :
                                    values.Frequency === 'Monthly' ?
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="MonthOnDays">Every month on day(s)
                                                <span className="validation-required">{" * "}</span>
                                                {errors.MonthOnDays && touched.MonthOnDays ? (<span className="validation-label" aria-hidden="false">{errors.MonthOnDays}</span>) : null}
                                            </label>
                                            <div className="form-control-feedback-group">
                                                <Field id="MonthOnDays" name="MonthOnDays" className="form-control" aria-describedby="name-help-block"
                                                    aria-invalid={errors.MonthOnDays && touched.MonthOnDays} aria-required="true" placeholder="Ex. 1,16,29"
                                                    required multiple />
                                                <span className="add-clear-x form-control-feedback" style={{
                                                    color: "rgb(51, 51, 51)",
                                                    cursor: "pointer",
                                                    textDecoration: "none",
                                                    overflow: "hidden",
                                                    position: "absolute",
                                                    pointerEvents: "auto",
                                                    right: 0,
                                                    top: 0,
                                                    zIndex: 100,
                                                    display: values.MonthOnDays && values.MonthOnDays.length > 0 ? undefined : "none",
                                                }}
                                                    onClick={() => setFieldValue("MonthOnDays", "")}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                            </div>
                                        </div> : ""}

                                {<div className="form-group">
                                    <label className="control-label" htmlFor="TimeZone">Time Zone
                                        <span className="validation-required">{" * "}</span>
                                        {errors.TimeZone && touched.TimeZone ? (<span className="validation-label" aria-hidden="false">{errors.TimeZone}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="TimeZone" component={FormikSelect} name="TimeZone"
                                            aria-invalid={errors.TimeZone && touched.TimeZone} aria-required="true" title={values.TimeZone ? values.TimeZone : "Select Time Zone"}
                                            required maxMenuHeight="200px" validationErrorMessage=""
                                            onChange={(value: any) => { setFieldValue('TimeZone', value) }} >
                                            {timeZones.map(tz => {
                                                return <FormikOption value={tz.value} key={tz.text}>{tz.text}</FormikOption>
                                            })}</Field>
                                    </div>
                                </div>}

                                {<div className="form-group">
                                    <label className="control-label" htmlFor="SceduledTime">Scheduled Time
                                        <span className="validation-required">{" * "}</span>
                                    </label>
                                    <table id="ScheduledDate" className="">
                                        <tr>
                                            <th scope="col">
                                                <Field id="Hour" component={FormikSelect} name="Hour"
                                                    aria-invalid={errors.Hour && touched.Hour} aria-required="true"
                                                    required maxMenuHeight="200px" title={values.Hour ? values.Hour : "Hour(s)"}
                                                    onChange={(value: any) => { setFieldValue('Hour', value) }} >
                                                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(yo => {
                                                        return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                    })}
                                                </Field>
                                            </th>
                                            <th scope="col" style={{ paddingLeft: "1rem" }}>
                                                <Field id="Mins" component={FormikSelect} name="Mins"
                                                    aria-invalid={errors.Mins && touched.Mins} aria-required="true"
                                                    required maxMenuHeight="200px" title={values.Mins ? values.Mins : "Min(s)"}
                                                    onChange={(value: any) => { setFieldValue('Mins', value) }} >
                                                    {['00', '15', '30', '45'].map(yo => {
                                                        return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                    })}
                                                </Field>
                                            </th>
                                            <th scope="col" style={{ paddingLeft: "1rem" }}>
                                                <Field id="Meridiem" component={FormikSelect} name="Meridiem"
                                                    aria-invalid={errors.Meridiem && touched.Meridiem} aria-required="true"
                                                    required maxMenuHeight="200px" title={values.Meridiem ? values.Meridiem : "AM/PM"}
                                                    onChange={(value: any) => { setFieldValue('Meridiem', value) }} >
                                                    {['AM', 'PM'].map(yo => {
                                                        return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                    })}</Field>
                                            </th>
                                        </tr>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            <ButtonRow style={isSubmitting ? { marginRight: "85%" } : { marginRight: "89%" }} >
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button purpose="primary" type="submit" id="btnCreate" disabled={isSubmitting}>{isSaving ? <>Saving...<ButtonSpinner /></> : "Save"}</Button>
                            </ButtonRow>
                        }
                    </ModalFooter>
                </Form>
            )
            }
        </Formik> :
            <table className="table table-striped table-bordered">
                <thead>
                    <tr style={{
                        backgroundColor: "#edecec"
                    }}>
                        <th scope="col">Subscription Name</th>
                        <th scope="col">Report Name</th>
                        <th scope="col">Recipients</th>
                        <th scope="col">Frequency</th>
                        <th scope="col">Author</th>
                        <th scope="col"></th>
                        <th scope="col">
                            <Button purpose="primary" id="createReport" style={{ float: "right" }} onClick={() => setShowModal(true)}>New Subscription</Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.SubscriptionDetails.length > 0 ? (props.SubscriptionDetails.map((item: any) => {
                        return <SubscriptionListItem key={item.subscriptionId} subscriptionId={item.subscriptionId} subscription={item}
                            reFetch={props.refetch} setSubscriptionId={setSubscriptionId} setShowModal={setShowModal} username ={props.username}
                        />
                    })) : (<tr>
                        <td colSpan={6}><NoDataError /></td></tr>)}
                </tbody>
            </table>}
    </DefaultPage>
    )
}