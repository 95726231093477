import { faChartPie, faStar } from "@fortawesome/free-solid-svg-icons"
import { ActionIcon, ToastId, Toasts } from "navex-react"
import React, { FC, useState } from "react"
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons"
import { useFavoriteReport } from "../PowerBIEmbeddedDashboard/PowerBIEmbeddedDashboardAPI"
import { getCustomerKey } from "../../Api/urls"

interface IProps {
  reportId: string,
  name: string,
  embedUrl: string,
  workSpaceId: string,
  description: string,
  collection: string,
  setReportMode: (mode: "View" | "") => void,
  setReportId: (reportId: string) => void,
  setEmbedUrl: (embedUrl: string) => void,
  reFetch: () => void
}

export const FavoriteRow: FC<IProps> = (props: IProps) => {

  let toastId: ToastId = 0
  const [favorite, setFavorite] = useState(true)

  const handleOnViewClick = () => {
    props.setReportId(props.reportId)
    props.setEmbedUrl(props.embedUrl)
    props.setReportMode("View")
  }

  const handleFavoriteClick = (fav: boolean) => {
    favoriteReport({ customerKey: getCustomerKey(), id: props.reportId, favorite: fav })
    setFavorite(fav)
  }

  const [favoriteReport] = useFavoriteReport({
    onSuccess: (_, data) => {
      if (data?.favorite)
        toastId = Toasts.success("Report added to favorites successfully!")
      else
        toastId = Toasts.success("Report removed from favorites successfully!")
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Something went wrong, Please try again later.")
      }
      setFavorite(!favorite)
    }
  })

  return (
    <tr>
      <td>{props.name}</td>
      <td style ={{lineHeight:"1.5"}}>{props.description}</td>
      <td>{props.collection.length ? props.collection : "Incident Management"}</td>
      <td><>
        <ActionIcon
          id={`${props.reportId}_ViewIcon`}
          toolTip={"View Report"}
          onClick={handleOnViewClick}
          icon={faChartPie}
          style={{ paddingRight: "3%" }} />
        <ActionIcon
          id={favorite ? `unfavoriteIcon` : `favoriteIcon`} 
          toolTip={favorite ? "Remove from Favorites" : "Add to Favorites"}
          icon={favorite ? faStar : faStarEmpty}
          onClick={() => { handleFavoriteClick(!favorite) }} />
      </>
      </td>
    </tr>
  )
}