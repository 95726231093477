import React, { useState } from 'react'
import { DefaultPage } from 'navex-react'
import { CustomFormulasDetailsView } from './CustomFormulasDetailsView'
import { CustomFormulasListView } from './CustomFormulasListView'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { useGetCustomFormulaInfo, IGetCustomFormula } from './CustomFormulasApi'
import { Accordion, Button, Card, PopOver } from 'navex-react'
import { CustomColumnsListView } from './CustomColumnsListView'
import { IGetCustomColumn, useGetCustomColumnInfo, useGetTables } from './CustomColumnsApi'
import { CalculatedColumnDetailView } from './CalculatedColumnDetailView'
import { DateDiffDetailView } from './DateDiffDetailView'
import { DateDiffListView } from './DateDiffListView'
import { IGetDateDiff, useGetDateDiffInfo } from './DateDiffApi'

export const CustomFormulasRoute = () => {

  const [isCustomFormulaEdit, setIsCustomFormulaEdit] = useState(false)
  const [isCalculatedColumnsEdit, setIsCalculatedColumnsEdit] = useState(false)
  const [AddDateDiff, setAddDateDiff] = useState(false)
  const [customId, setCustomFormulaId] = useState(undefined as number | undefined)
  const [columnId, setCustomColumnId] = useState(undefined as number | undefined)
  const [dateDiffId, setDateDiffId] = useState(undefined as number | undefined)
  const [activeCardIndex, setActiveCardIndex] = useState(-1)

  const [{ customFormulaInfo, loading, errors, status }, reFetchData] = useGetCustomFormulaInfo()
  const [{ customColumnInfo, errors: customColumnErrors, loading: customColumnLoading, status: customColumnStatus }, reFetchData1] = useGetCustomColumnInfo()
  const [{ tablesInfo, errors: tableErrors, loading: tableDataLoading, status: tableDataStatus }] = useGetTables()
  const [{ dateDiffInfo, errors: dateDiffErrors, loading: dateDiffLoading, status: dateDiffStatus }, reFetchData2] = useGetDateDiffInfo()

  const customFormula: IGetCustomFormula | undefined = customFormulaInfo.find(f => f.id === customId)
  const customColumn: IGetCustomColumn | undefined = customColumnInfo.find(f => f.id === columnId)
  const dateDiff: IGetDateDiff | undefined = dateDiffInfo.find(f => f.id === dateDiffId)

  const handleAddCustomFoumulaClick = () => {
    setIsCustomFormulaEdit(true)
    setIsCalculatedColumnsEdit(false)
    setAddDateDiff(false)
    setCustomFormulaId(undefined)
  }

  const handleAddCustomColumnClick = () => {
    setIsCustomFormulaEdit(false)
    setIsCalculatedColumnsEdit(true)
    setAddDateDiff(false)
    setCustomColumnId(undefined)
  }

  const handleAddDateDiffClick = () => {
    setAddDateDiff(true)
    setIsCustomFormulaEdit(false)
    setIsCalculatedColumnsEdit(false)
    setDateDiffId(undefined)
  }

  const cardChanged = (newActiveCardIndex: number) => {
    setActiveCardIndex(newActiveCardIndex)
  }

  if (errors.length || customColumnErrors.length || tableErrors.length || dateDiffErrors.length) return <UnexpectedError />
  if (loading || !status || customColumnLoading || !customColumnStatus || tableDataLoading || !tableDataStatus || dateDiffLoading || !dateDiffStatus) return <Spinner />

  if (isCustomFormulaEdit && !isCalculatedColumnsEdit && !AddDateDiff)
    return <DefaultPage>
      <CustomFormulasDetailsView setIsCustomFormulaEdit={setIsCustomFormulaEdit} reFetchData={reFetchData} customFormula={customFormula} customFormulaInfo={customFormulaInfo} />
    </DefaultPage>

  else if (!isCustomFormulaEdit && isCalculatedColumnsEdit && !AddDateDiff)
    return <DefaultPage>
      <CalculatedColumnDetailView setIsCalculatedColumnsEdit={setIsCalculatedColumnsEdit} reFetchData={reFetchData1} customColumn={customColumn} customColumnInfo={customColumnInfo} tableData={tablesInfo} />
    </DefaultPage>

  else if (!isCustomFormulaEdit && !isCalculatedColumnsEdit && AddDateDiff)
    return <DefaultPage>
      <DateDiffDetailView setAddDateDiff={setAddDateDiff} reFetchData={reFetchData2} dateDiff={dateDiff} dateDiffInfo={dateDiffInfo} tableData={tablesInfo} />
    </DefaultPage>

  else return <DefaultPage>
    <div style={{ display: 'flex', flexFlow: 'row-reverse' }}>
      <PopOver id="helpText" placement='auto' size='large'>
        A calculated column adds a static column to the dataset, similar to a formula-based column in a spreadsheet.
        Columns are used for date differences, arithmetic and grouping values. Measures are dynamic calculations performed on the fly when you interact with the reports. Measures are used for aggregations.
      </PopOver>
    </div>
    <Accordion activeCardIndex={activeCardIndex} onActiveCardChange={cardChanged}>
      <Card title={"Measures"} actions={<Button purpose="primary" id="AddCustomFormula"
        onClick={handleAddCustomFoumulaClick} >New Measure</Button>}>
        <CustomFormulasListView customFormulaInfo={customFormulaInfo} setIsCustomFormulaEdit={setIsCustomFormulaEdit} reFetchData={reFetchData}
          setCustomFormulaId={setCustomFormulaId} customId={customId} />
      </Card>
      <Card id="columnCard" title={"Columns"} actions={<Button purpose="primary" id="AddCustomColumn"
        onClick={handleAddCustomColumnClick}>New Column</Button>}>
        <CustomColumnsListView setIsCalculatedColumnsEdit={setIsCalculatedColumnsEdit} customColumnInfo={customColumnInfo} reFetchData={reFetchData1} setCustomColumnId={setCustomColumnId} columnId={columnId} />
      </Card>
      <Card id="dateDiff" title={"DateDiff Columns"} actions={<Button purpose="primary" id="AddDateDiff"
        onClick={handleAddDateDiffClick}>New DateDiff</Button>}>
        <DateDiffListView setAddDateDiff={setAddDateDiff} reFetchData={reFetchData2} dateDiffInfo={dateDiffInfo} setDateDiffId={setDateDiffId} dateDiffId={dateDiffId} />
      </Card>
    </Accordion>
  </DefaultPage>
} 
