import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getTenantId } from "../../Api/urls"
import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from '../../Api/useAxios'

export interface IGetDateDiff {
    id: number,
    tenantId: string,
    fromDateTable: string,
    fromDateColumn: string,
    toDateTable: string,
    toDateColumn: string,
    columnDisplayName: string,
    destinationTable: boolean,
    duration: string
}

export interface IUpsertDateDiffPayload {
    Id: number | undefined,
    TenantId: string,
    FromDateTable: string,
    FromDateColumn: string,
    ToDateTable: string,
    ToDateColumn: string,
    ColumnDisplayName: string,
    OldColumnName?: string,
    DestinationTable?: boolean,
    Duration: string
}

type TUseGetDateDiff = [
    {
        dateDiffInfo: IGetDateDiff[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]


export const useGetDateDiffInfo = (): TUseGetDateDiff => {

    const getDateDiffUrlPath = `${buildServerApiUrl("PowerBi.GetDateDiffColumns").toString()}/${getTenantId()}`

    const [{ data, loading, errors, status }, reFetchData] = useAxiosGet(
        getDateDiffUrlPath
    )
    let dateDiffInfo: IGetDateDiff[] = []
    if (data) {
        dateDiffInfo = data
    }
    return [{ dateDiffInfo, loading, status, errors }, reFetchData]
}

export type IUpsertDateDiff = Omit<IUpsertDateDiffPayload, "TenantId">

type TUseUpsertDateDiff = [
    (payload: IUpsertDateDiff) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const upsertDateDiffUrl = `${buildServerApiUrl("PowerBi.UpsertDateDiffColumn").toString()}`
export const useUpsertDateDiff = (
    options?: IUseAxiosOptions<IUpsertDateDiffPayload>
): TUseUpsertDateDiff => {


    const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertDateDiffPayload>(
        upsertDateDiffUrl, {}, options
    )

    const upsertDateDiff = async (payload: IUpsertDateDiff) => {
        const res = await upsert({ ...payload, TenantId: getTenantId() })
        return res
    }

    return [upsertDateDiff, { posting, status, errors }]
}


export interface IGetTables {
    tableName: string,
    tableAlias: string,
    columns: string[]
}

type TUseGetTables = [
    {
        tablesInfo: IGetTables[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]


export const useGetTablesForDateDiff = (): TUseGetTables => {

    const getTablesUrlPath = `${buildServerApiUrl("PowerBi.GetTablesAndColumnsForDateDiff").toString()}/${getTenantId()}`

    const [{ data, loading, errors, status }, reFetchData] = useAxiosGet(
        getTablesUrlPath
    )
    let tablesInfo: IGetTables[] = []
    if (data) {
        tablesInfo = data
    }
    return [{ tablesInfo, loading, status, errors }, reFetchData]
}

interface IDeleteDateDiffPayload {
    Id: number
    TenantId: string
}

type IDeleteDateDiff = Omit<IDeleteDateDiffPayload, "TenantId">

type TUseDeleteDateDiff = [
    (payload: IDeleteDateDiff) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const deleteDateDiffUrl = `${buildServerApiUrl("PowerBi.DeleteDateDiffColumn").toString()}`
export const useDeleteDateDiff = (
    options?: IUseAxiosOptions<IDeleteDateDiffPayload>
): TUseDeleteDateDiff => {
    const [execute, { posting, status, errors }] = useAxiosPost<IDeleteDateDiffPayload>(
        deleteDateDiffUrl, {}, options
    )

    const clear = async (payload: IDeleteDateDiff) => {
        const res = await execute({ ...payload, TenantId: getTenantId() })
        return res
    }

    return [clear, { posting, status, errors }]
}
