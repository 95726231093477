import React, { FC } from 'react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { ManageSubscription } from './ManageSubscription'
import { useGetSubscriptionDetails } from './SubscriptionApi'
import { IPowerBiReports } from '../../Models/GlobalState'

interface IProps {
    reportsLeftNavMenu: IPowerBiReports[],
    username :string
}

export const SubscriptionRoute: FC<IProps> = (props) => {
    const username = props.username

    const reports: string[] = props.reportsLeftNavMenu.map((item: any) => item.powerBiReports);
    const [{ SubscriptionDetails, loading, errors, status }, reFetchData] = useGetSubscriptionDetails()

    if (errors.length) return <UnexpectedError />
    if (loading || !status) return <Spinner />

    return (
        <ManageSubscription SubscriptionDetails={SubscriptionDetails} refetch={reFetchData} leftNavMenu={reports} username ={username} />
    )
}