import React, { FC, useState } from 'react'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { IGetSubscription, useDeleteSubscription } from './SubscriptionApi'
import { ActionIcon, Modal, ToastId, Toasts } from 'navex-react'

interface IProps {
    subscriptionId: number | undefined,
    subscription: IGetSubscription,
    reFetch: () => void,
    setSubscriptionId: (subscriptionId : number | undefined) => void,
    setShowModal: (showModal: boolean) => void,
    username:string
}

export const SubscriptionListItem: FC<IProps> = (props) => {
    const [showModalDeleteSubscription, setShowModalDeleteSubscription] = useState(false)
    let toastId: ToastId = 0
    const [deleteSubscription] = useDeleteSubscription({
        onSuccess: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.success(`Deleted Successfully`)
            }
            props.reFetch()
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const handleSubscriptionDelete = () => {
        if (props.subscriptionId !== undefined) {
            deleteSubscription({ subscriptionId: props.subscriptionId })
        }
    }

    const handleEditSubscriptionBtnLinkClick = () => {
        props.setSubscriptionId(props.subscriptionId)
        props.setShowModal(true)
    }

    switch (props.subscription.frequency) {
        case 2:
            props.subscription.frequency = "Daily"
            break
        case 3:
            props.subscription.frequency = "Weekly"
            break
        case 4:
            props.subscription.frequency = "Monthly"
            break
    }

    return <tr key={props.subscriptionId}>
        <td>{props.subscription.subscriptionName}</td>
        <td>{props.subscription.reportName}</td>
        <td>{props.subscription.recipients}</td>
        <td>{props.subscription.frequency}</td>
        <td>{props.subscription.author}</td>
        <td style={{ display: "flex" }}>
            <ActionIcon
                id={`_editIcon`}
                toolTip={"Edit subscription"}
                icon={faPencilAlt}
                onClick={handleEditSubscriptionBtnLinkClick}
            />
            <ActionIcon
                id={`_deleteIcon`}
                toolTip={"Delete field"}
                icon={faTrashAlt}
                onClick={() => setShowModalDeleteSubscription(true)}
            />
            <Modal
                id={props.subscriptionId + "_modalDeleteSubscription"}
                headerText={"Delete Subscription"}
                show={showModalDeleteSubscription}
                okButtonText={"Delete"}
                message={`Are you sure you want to delete ${props.subscription.subscriptionName}?`}
                onClickedOk={handleSubscriptionDelete}
                onClickedCancel={() => setShowModalDeleteSubscription(false)}
            />
        </td>
        <td></td>
    </tr>
}