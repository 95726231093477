import { IAuthenticationState } from '../Models/GlobalState'
import { createActionCreators, createReducerFunction } from "immer-reducer"
import { ImmerReducer } from "immer-reducer"

class AuthenticationReducerDef extends ImmerReducer<IAuthenticationState> {
    confirmAuthentication(userData: unknown) {
        const castData = userData as IAuthenticationState
        this.draftState.username = castData.username
        this.draftState.puid = castData.puid
        this.draftState.tenantId = castData.tenantId
        this.draftState.firstName = castData.firstName
        this.draftState.lastName = castData.lastName
        this.draftState.dashboardCollections = castData.dashboardCollections
        this.draftState.defaultDashboard = castData.defaultDashboard
        this.draftState.defaultDashboardCollection = castData.defaultDashboardCollection
        this.draftState.permissions = castData.permissions
        this.draftState.productPermissions = castData.productPermissions
        this.draftState.toggles = castData.toggles
        this.draftState.featureConfigurations = castData.featureConfigurations
        this.draftState.authorizationToken = castData.authorizationToken
        this.draftState.reportsLeftNavMenu = castData.reportsLeftNavMenu
        this.draftState.featureConfigurations = castData.featureConfigurations
    }

    errorGettingAuthentication(errorKey: number) {
        this.draftState.authenticationUnexpectedError = errorKey
    }
} 

export const AuthenticationActionCreators = createActionCreators(AuthenticationReducerDef)
export const authentication = createReducerFunction(AuthenticationReducerDef, {})